.domain-listing {
  .accordion-button {
    color: inherit;

    &:focus {
      outline: none;
      box-shadow: none; /* This removes any shadow that might appear on focus */
    }
  }

  .domain-action-buttons {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }

  .email-details {
    a {
      text-decoration: none;
      border-bottom: 1px dashed #999;
    }
  }
}

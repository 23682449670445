.column-section-editor {
  thead, tbody, tfoot, tr, td, th {
    border-color: var(--separator-color-light);
  }

  th, td {
    padding: 6px;
  }

  .input-group {
    font-size: 12px;

    input[type="number"] {
      padding: 1px;
      width: 12px;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    button {
      padding: 3px;
    }
  }
  .column-section-drag {
    display: contents;
  }
}
.column-grid {
  table {
    td {
      vertical-align: middle;
    }
  }
}
@use '../../../assets/css/sass/variables.scss' as *;

.BreakPoint {
  // Hide up/down arrows in number fields, they take up too much space on small screens.
  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance:textfield;
  }

  .input-group-append {
    .btn {
      border-radius: inherit;
      border-color: var(--separator-color);
      background-color: inherit;
      color: var(--primary-color);
    }
  }

  ul {
    list-style: none;
    display: flex;
    padding-left: 15px;
    margin: 0px;
    width: 100%;

    li:nth-child(1) {
      padding-right: 5px;
    }

    .bp-settingtype {
      padding-right: 5px;
    }
  }
  button.breakpoint-txt-btn.btn.btn-secondary {
    background: none;
    color: $color-grey;
    border: none;
    border-radius: 0;
    vertical-align: unset;
    margin-left: 2.5px;
    margin-right: 2.5px;
    padding: 0;

    i {
      font-size: 100%;
    }
    &:active, &:hover {
      background: none;
      color: $color-sky-blue-dark;
    }
  }
}

.box-modal .row {
  justify-content: center;
}

.WGColorPicker {
  // The component editor can have a lot of colour fields, so make them a bit smaller.
  input.form-control, span.input-group-text, button.input-group-text {
    line-height: 28px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.SiteBriefing {
    .briefing-nav {
        position: sticky;
        top: 100px; // Height of the top nav bar
        z-index: 2;

        color: #6c757d;

        .card-body {
            overflow: auto;
            white-space: nowrap;

            .breadcrumb {
                display: block;

                li {
                    display: inline-block;
                }
            }
        }

        a {
            text-decoration: none;
            color: #303030;
            padding: 0.25rem 0.5rem;

            &.active {
                font-weight: bold;
            }
        }
    }

    a.anchor {
        position: relative;
        top: -210px;
    }

    .row:not(.include-phase-2) {
        .phase-2 {
            display: none;
        }
    }

    .phase-2 {
        background-color: #eee;
    }

    aside {
        font-style: italic;
        padding: 0 2rem 1rem 2rem;
    }

    .prompt {
        color: var(--theme-color-2);
    }

    .indented {
        padding: 0 2rem 0 2rem;
    }

    .card.indented {
        margin-left: 4rem;
        max-width: calc(100% - 4rem);
    }
}

@media print {
    .SiteBriefing {
        input, textarea, .react-select__control {
            border: 0 !important;
            resize: none;
        }
        .briefing-nav, .BottomActionToolbar, .hide-pdf, .HeaderBreadcrumb, .react-select__indicators {
            display: none !important;
        }
    }
}

@use '../../../../assets/css/sass/variables.scss' as *;

.ImageGallery {
  .custom-dropdown-button {
    background: transparent !important;
    color: #000 !important;
    border: 1px solid $color-semi-grey !important;
    border-radius: 0 !important;
    border-left: none !important;
  }
}

.DiscussionDeck {
	--blue: #1a5365;
	--white: #fff;

	@page {
		size: 297mm 167mm;
		margin: 0;
	}

	html {
		box-sizing: border-box;
	}

	*,
	*:before,
	*:after {
		box-sizing: inherit;
		text-rendering: geometricprecision !important;
	}

	html,
	body {
		font-family: 'Open Sans';
		font-weight: 400;
		font-size: 17px;
		margin: 0;
		width: 100%;
	}

	/* These are just to make it look like Chrome's PDF viewer */
	@media screen {
		body {
			background-color: rgb(82, 86, 89);

			.page {
				box-shadow: 0px 8px 16px #333;
				margin: 2.5vh auto 5vh auto;
			}
		}
	}

	.page {
		width: 297mm;
		height: 167mm;

		aspect-ratio: 16/9;
		overflow: hidden;

		background-color: white;
		background-size: cover;
		clear: both;
		page-break-after: always;
		position: relative;
		display: grid;
		align-items: center;
		padding: 0 10%;
		color: var(--blue);

		&.intro-page {
			align-items: stretch;
		}

		&.project-manager-page {
			align-items: stretch;
			padding: 0;
			grid-template-areas: 'left right';
			grid-template-columns: 55% 45%;
			background-color: var(--blue);
			color: white;

			.left {
				grid-area: left;
				padding: 12% 15%;
			}

			.right {
				grid-area: right;
				padding: 0;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}

		/* This is just to center the content when emulating print mode on a screen, it doesn't do anything when actually printing or when viewing as a web page. */
		margin: 0 auto;

		button {
			background-color: white;
			color: var(--blue);
			border: 0;
			padding: 10px 50px;
			border: 2px solid white;
			border-radius: 8px;
			font-weight: 700;

			&.btn-secondary {
				background-color: var(--blue);
				color: white;
			}
		}
	}

	h1 {
		font-size: 3rem;
		font-weight: 100;
		margin-bottom: 14px;
	}

	h2 {
		font-size: 2.5rem;
		font-weight: 100;
		margin-bottom: 14px;
	}

	p {
		margin-bottom: 12px;
		line-height: 1.4rem;
	}

	b, strong, .b {
		font-weight: 700;
	}

	li {
		font-size: 1rem;
		margin-top: 3px;
		margin-bottom: 3px;
	}

	.border {
		font-size: 1.3rem;
	}

	table { color: inherit; }

	.intro-page {
		grid-template-areas: 'logo right' 'left right';
		grid-template-columns: 45% 55%;
		grid-template-rows: 30% 70%;
		padding: 0;

		.logo-cont {
			grid-area: logo;
			background-image: url('Dashboard/src/assets/images/discussion-deck/full-logo.webp');
			background-size: 50%;
			background-repeat: no-repeat;
			background-position: center;
		}

		.left-cont {
			grid-area: left;
			background-color: var(--blue);
			color: white;
			padding: 8% 10%;
		}

		.right-cont {
			grid-area: right;
			background-image: url('Dashboard/src/assets/images/bdq/page-1.jpg');
			background-size: cover;
		}
	}

	.inverse-page {
		background-color: var(--blue);
		color: var(--white);
	}

	.white {
		color: white;
	}

	.green {
		color: #59bc6c !important;
	}

	.red {
		color: #ed5856;
	}

	.grey {
		color: #666766;
	}

	.light-grey {
		color: #d8d8d8;
	}

	.blue {
		color: #4bafcc;
	}

	.grey-head {
		color: #E5F4F8;
		font-size: 10px;
		height: 30px;
	}

	.nowrap {
		white-space: nowrap;
	}

	.footer {
		position: absolute;
		top: 1070px;
		left: 0;
		color: white;
		padding: 15px 52px;
		font-size: 14px;
	}

	.contact-details-page {
		td {
			text-overflow: ellipsis;
	    max-width: 200px;
  	  overflow: hidden;
		}
	}

	.selling-points-page ol,
	.keyword-phrases-page ul,
	.page-keywords-page ul {
		margin-top: 10px;
		padding-left: 20px;
	}

	.content-topics-page ol {
		margin-top: 30px;
	}

	.content-topics-page li,
	.content-differentiators-page li {
		margin-bottom: 16px;
	}

	.photos-upload-page {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	.photos-upload-page button {
		margin-top: 30px;
		font-size: 1.2rem;
		padding: 15px 60px;
	}

	.owner-quote-page blockquote {
		margin: 30px 0;
		padding: 20px;
		border-left: 4px solid var(--blue);
		font-style: italic;
	}

	.grid-container {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 20px;
		margin-top: 30px;
	}
}

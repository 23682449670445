.cta-log-more-info {
  span {
    border-style: dashed;
    border-width: 0 0 1px 0;
  }
}

@media only screen and (max-width: 768px) {
  .cta-table {
    font-size: 0.75rem;
  }
}

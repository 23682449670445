@use '../../assets/css/sass/variables.scss' as *;

.instance-selection-cont {
  .InstanceSelection, .SiteSelection {
    span {
      display: inline-block;
      white-space: nowrap;
      max-width: min(calc(50vw - (10px + 16px)), 300px); // 10px for the padding, 16px for the caret
      overflow: hidden;
      text-overflow: ellipsis;
    }

    i.fa-caret-down {
      // Not sure why but the caret was sitting too low, this fixes it. Probably a better way to do this but I can't
      // see it right now.
      vertical-align: super;
    }
  }

  .InstanceSelection {
    anchor-name: --InstanceSelection;

    &.react-select .react-select__menu {
      width: 100%;
      min-width: 480px;
      // border-top-width: 1px !important;
      // border-top-style: solid !important;
      // The colour isn't working, but oh wells.
      // border-top-color: rgba(var(--theme-color-1), 0.6) !important;
    }
  }

  @media (max-width: 480px) {
    .InstanceSelection {
      &.react-select .react-select__menu {
        width: 280px;
      }
    }
  }

  .SiteSelection {
    position: relative;

    @media (min-width: 768px) {
      font-weight: 600;
    }

    a {
      text-decoration: none;
      color: $color-sky-blue-light;
      white-space: nowrap;

      i {
        margin-left: 4px;
      }
    }
  }

  .site-selector-list {
    position: absolute;
    top: -13px;
    left: -13px;
    border: 1px solid $color-sky-blue-light;
    border-radius: 0.1rem;
    background: white;
    padding: 12px;

    div {
      margin-bottom: 4px;
    }
  }

  .instance-selection-hover-cont {
    position: absolute;
    top: anchor(--InstanceSelection bottom);
    background: white;
    width: 506px;
    z-index: 1000;

    .instance-selection-select-cont {
      height: 370px;
      margin-top: 3px;
      border: 1px solid $color-sky-blue-light;
      border-radius: 0.1rem;
      padding: 12px;
    }
  }
}

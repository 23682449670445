.SaveAndContinue {
  display: inline-block;
  button {
    padding: 0;
  }
  .save-btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 1px solid white;
  }
  .continue-btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
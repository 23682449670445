.user-presence {
  height: 32px;
  margin-right: 1rem;

  img {
    width: 32px;
    height: 32px;
    border-radius: 40px;
  }

  div {
    padding: 0;
    font-size: 12px;
    color: white;
    width: 32px;
    border-radius: 40px;
    height: 32px;
    line-height: 12px;
    font-family: 'Arial';
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: default;
  }
}

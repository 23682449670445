.PMMeeting {
  .plain-layout {
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100vh;
  }

  .top {
    overflow-y: hidden;
  }

  .bottom {
    padding: 20px;
    text-align: center;

    .container-fluid {
      height: 200px;
    }

    .navigation {
      button {
        margin: 0 5px;
        padding: 8px 16px;
        cursor: pointer;

        /* These are taken from DiscussionDeck/index.css */
        background-color: white;
        color: #45afd0;
        border: 2px solid white;
        border-radius: 8px;
        font-weight: 700;

        &.btn-secondary {
          background-color: var(--blue);
          color: white;
        }

        &:disabled {
          color: #ccc;
          background-color: #f0f0f0;
          pointer-events: none;
        }
      }
    }

    textarea {
      font-family: inherit;
      font-size: 14px;
      border-radius: 4px;
      border: 1px solid #ccc;
      width: 80%;
      padding: 10px;
      resize: vertical;
    }
  }

  &.PMMeetingSingleWindow {
    .top {
      width: calc(100% - 335px);
    }

    .bottom {
      width: calc(100% - 335px);

      .container-fluid {
        height: 200px;
      }

      textarea {
        width: 100%;
        height: 100%;
      }
    }
  }

  &.PMMeetingDualWindow {
    .bottom {
      width: calc(100% - 335px);

      .container-fluid {
        height: 600px;
      }

      textarea {
        width: 100%;
        height: 100%;
      }

      .navigation {
        button {
          font-size: 16px;
        }
      }
    }
  }
}

.media-upload {
  .dropzone {
    min-height: 125px !important;
    border: 1px solid var(--separator-color) !important;
    background: var(--input-background) !important;
    padding: 10px 10px !important;
    border-radius: var(--border-radius) !important;
    color: var(--primary-color) !important;
    height: auto !important;
    float: left;
    margin-bottom: 15px;
    width: 100%;
    .img-thumbnail {
      height: 60px !important;
      width: 60px !important;
      object-fit: cover !important;
    }
  }
}

.img-fullsize-preview {
  overflow: scroll;
  height: 450px;
}

.media-listing-outer {
  .custom-ul {
    li {
      display: block;
      padding: 0;
    }
  }
}

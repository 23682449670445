button.dashboard-chat-button {
  position: fixed;
  right: 0;
  top: 60%;
  background-color: var(--theme-color-1);
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 4px 0 0 4px !important;
  padding: 12px 6px 8px 6px;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25) !important;

  span {
    display: block;
    white-space: nowrap;
    font-weight: 500;
  }
}

.dashboard-chat {
  img {
    max-width: 150px;
  }
}

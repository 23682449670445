@keyframes nlux-ldr-spin {
	0%,
	100% {
			box-shadow: .2em 0px 0 0px currentcolor;
	}
	12% {
			box-shadow: .2em .2em 0 0 currentcolor;
	}
	25% {
			box-shadow: 0 .2em 0 0px currentcolor;
	}
	37% {
			box-shadow: -.2em .2em 0 0 currentcolor;
	}
	50% {
			box-shadow: -.2em 0 0 0 currentcolor;
	}
	62% {
			box-shadow: -.2em -.2em 0 0 currentcolor;
	}
	75% {
			box-shadow: 0px -.2em 0 0 currentcolor;
	}
	87% {
			box-shadow: .2em -.2em 0 0 currentcolor;
	}
}

.chat {
	padding-bottom: 20px;

	.chat-body {
		padding: 10px 0;
		overflow-y: auto;
		scrollbar-width: thin;
	}

	input {
		background: var(--background-color);
		border: 0;
		border-radius: 10px;
		font-size: 1rem;

		&:focus {
			background: var(--background-color);
			border-color: var(--separator-color);
		}
	}

	.input-group-text {
		background: var(--background-color);
		border: 0;
		border-radius: 10px;
	}

	button {
		background: none;
		border: none;
	}

	.chat-message {
		display: flex;
		margin-bottom: 10px;
		align-items: end;

		img {
			width: 42px;
			height: 42px;
		}
	}

	.chat-message-body {
		white-space: pre-wrap;
		max-width: 85%;
    background: var(--background-color);
		border-radius: 10px;
    padding: 10px 20px;
		font-size: 1rem;
	}

	.chat-message-from-user {
		justify-content: flex-end;

		.chat-message-body {
			background: var(--theme-color-1);
			color: var(--foreground-color);
		}
	}

	.chat-message-waiting {
		display: flex;
		align-items: center;

		> .nlux-comp-loaderContainer {
			width: 17px;
			padding-left: 10px;

			> .spinning-loader {
				display: inline-block;
				width: 15px;
				height: 15px;

				border-radius: 50%;
				aspect-ratio: 1 / 1;
				perspective: 1000px;

				&:before,
				&:after {
					color: #777;
					position: absolute;
					top: 0;
					left: 0;
					display: block;
					width: inherit;
					height: inherit;
					content: '';
					transform: rotateX(70deg);
					animation: 1s nlux-ldr-spin linear infinite;
					border-radius: 50%;
				}

				&:after {
					transform: rotateY(70deg);
					animation-delay: .4s;
				}
			}
		}
	}
}

.bdq {
  background-color: #1a5365 !important;

  color: white;

  font-family: 'Open Sans', sans-serif;
  p, li, h1, h2, h3, h4, h5, h6, label {
    font-family: 'Open Sans', sans-serif;
  }

  font-size: 1.2em;

  p {
    line-height: 1.5em;
  }

  h1 { font-size: 3.5em; }
  h2 { line-height: 1.3; font-size: 1.75em; }
  h3 { line-height: 1.3; }

  button {
    background-color: white;
    color: #1a5365;
    border: 0;
    padding: 10px 44px;
    border: 2px solid white;
    border-radius: 4px;
    font-weight: 700;

    &:hover {
      background-color: transparent;
      color: white;
      border-color: white;
    }
  }

  .radio-item {
    label {
      margin-bottom: 1rem;
    }

    .form-check-input {
      margin-right: 1rem;
    }
  }

  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .scroll-container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .page-container {
    background-color: #1a5365 !important;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: white;
    transition: transform 0.25s ease-in-out;
    transform: translateY(100%);
    opacity: 0;
    visibility: hidden;
    overflow-y: scroll;

    &.active {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }
  }

  .page {
    width: 100%;
    min-height: 100vh;

    &.has-bottom-box {
      > div {
        margin-bottom: -113px;
      }
    }
  }

  .bottom-bar {
    z-index: 2;

    .progress {
      height: 15px;
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);

      .progress-bar {
        background-color: #45afd0;
      }
    }
  }

  textarea:placeholder-shown {
    font-style: italic;
  }

  .page-intro {
    display: grid;
    grid-template-columns: 54% 46%;

    > :nth-child(1) {
      align-content: center;
      padding: 7% 15%;
    }
    > :nth-child(2) {
      background-image: url('Dashboard/src/assets/images/bdq/page-1.jpg');
      background-size: cover;
    }
  }

  .page-book {
    display: grid;
    grid-template-columns: 54% 46%;

    > :nth-child(1) {
      align-content: center;
      padding: 7% 15%;
    }
    > :nth-child(2) {
      background-image: url('https://cdn.webgenius.co.nz/cdn-cgi/image/format=auto%2Cwidth=1300/https://cdn.webgenius.co.nz/c/6642076d3a556b44d05c8e03/kiwi-business-book?h=617446cd');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    h2, h3, p {
      margin-bottom: 1.5rem;
    }
  }

  @media (max-width: 768px) {
    .page-intro, .page-book {
      display: block;

      > :nth-child(1) {
        padding: 7% 10%;
      }
    }
  }

  .generic-page {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 115px;
    gap: 0px 0px;
    grid-template-areas:
      "Main"
      "BottomBar";
    padding: 7% 15%;

    .main { grid-area: Main; }
    .bottom-bar { grid-area: BottomBar; }
  }

  @media (max-width: 768px) {
    .generic-page {
      padding: 7% 10%;
    }

    h1 {
      font-size: 2.5em;
    }
  }

  .page-cont {
    position: relative;

    .bg-image-cont-1 {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 50vw;
      background-image: url('https://cdn.webgenius.co.nz/cdn-cgi/image/format=auto%2Cwidth=3000/https://cdn.webgenius.co.nz/c/67ca6da607c7d19debba977d/kate?h=f6286ee9');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .page-ad-1 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 115px;
    gap: 0px 0px;
    grid-template-areas:
      "Main"
      "BottomBar";
    width: 100vw;
    padding: 7% 15%;

    .blue-stroke {
      position: absolute;
      top: 48px;
      bottom: 48px;
      left: 48px;
      width: 6px;
      background-color: #1a5365;
      border-radius: 6px;
    }

    .main { grid-area: Main; }
    .bottom-bar { grid-area: BottomBar; }

    .ad-box-1 {
      width: 400px;
      position: absolute;
      left: 28%;
      background-color: white;
      color: #4e4e4e;
      font-size: 32px;
      box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.5);
      padding: 48px 48px 48px 96px;
    }
  }
}

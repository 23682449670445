.backgroundJobs {
  .progress-column {
    .progress{
      height: 20px;
      font-weight: 700;
      font-size: 15px;
    }
  }
}

.JobReport {
  .graph {
    width: 70%;
    margin: 0 auto;
  }
}

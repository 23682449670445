.custom-react-select {
  // The select box in the Versioning box is too short for some reason, can't figure out why.
  .react-select__control {
    min-height: 42.45px;
  }

  .react-select__single-value {
    min-height: 20.22px;
  }
}

.dropdown-options-li {
  display: block;
  list-style: none;
  margin-bottom: 10px;
  input.form-control {
    width: 30%;
    display: inline-block;
    margin-right: 15px;
  }
}
.disabled-field {
  background-color: #efefef !important;
}

@media only screen and (max-width: 768px) {
  .dropdown-options-li input.form-control {
    width: 80%;
  }
}

button.dashboard-support-button {
  position: fixed;
  right: 0;
  top: 50%;
  transform: rotate(90deg);
  transform-origin: top right;
  background-color: var(--theme-color-1);
  color: white;
  border: none;
  border-radius: 0 0 4px 4px !important;
  padding: 6px 12px;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25) !important;

  span {
    display: block;
    white-space: nowrap;
    font-weight: 500;
  }
}

.dashboard-support-form {
  .alert {
    margin-bottom: 15px;
  }

  img {
    max-width: 150px;
  }
}

.new-new-editor {
  .editor__content {
    > div {
      min-height: 200px;

      &:focus-visible {
        outline: none;
      }
    }

    ul, ol {
      list-style-position: outside;
    }
  }

  .editor-toolbar ul {
    gap: 4px;

    li {
      padding: 8px;
    }

    .mark-active {
      background-color: var(--light-btn-background);
    }
  }
}

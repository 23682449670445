@use '../../../assets/css/sass/variables.scss' as *;

.custom-search-expression-outer {
  .custom-search-expression-input.invalid-expression {
    border-color: $error-color;
  }
  .alert-danger {
    font-size: 11px;
    font-weight: $font-weight6;
  }
}
.custom-date-bx {
  border-radius: 4px;
  background: none;
  color: hsl(0, 0%, 20%);
  width: $width100P;
  padding: 10px 15px;
  border: 1px solid hsl(0, 0%, 80%);
}

.custom-date-bx.allow-disabled {
  background-color: $color-light-grey !important;
}
.member-search-fields-outer {
  div.row {
    align-items: $center;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
    }
  }
}
.custom-bg-card.card {
  padding: 50px 30px;
}

.flex-none {
  flex: none;
}

.dashboard-chat-sidebar {
  position: fixed;
  top: 100px;
  right: -460px;
  bottom: 0;
  width: 460px;
  background-color: var(--foreground-color);
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.15);
  z-index: 1026;
  transition: right 0.3s ease;
  display: flex;
  flex-direction: column;

  &.open {
    right: 0;
  }

  .dashboard-chat-sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
  }

  .dashboard-chat-sidebar-header h5 {
    margin: 0;
    font-weight: 600;
  }

  .chat-close-button {
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: var(--text-color);
    padding: 5px;
  }

  .dashboard-chat-sidebar-content {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    scrollbar-width: none;
  }

  .dashboard-chat {
    img {
      max-width: 150px;
    }
  }

  .chatbot {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 576px) {
  .dashboard-chat-sidebar {
    width: 100%;
    right: -100%;
  }
}

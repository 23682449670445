@use '../../../assets/css/sass/variables.scss' as *;

.site-tree-move {
  margin-right: 10px;
  background-color: $color-white;
  color: #3A3A3A;
  border-radius: 20px;
  padding: 2px 5px;
}

.rst__tree.no-structure {
  .rst__lineBlock {
    display: none;
  }
}

.rst__node {
  &:nth-child(even) {
    background-color: var(--background-color);
  }

  .rst__nodeContent {
    right: 0;

    .rst__nodeContentInner {
      width: 280px;
    }
  }
}

.bulk-editing-container {
  position: absolute;
  top: 25px;
  right: 28px;
  overflow-x: scroll;

  // left: (44 * (X + 1)) + 314
  &.bulk-editing-padder-0 { left: 358px; }
  &.bulk-editing-padder-1 { left: 402px; }
  &.bulk-editing-padder-2 { left: 446px; }
  &.bulk-editing-padder-3 { left: 490px; }
  &.bulk-editing-padder-4 { left: 534px; }
  &.bulk-editing-padder-5 { left: 578px; }
  &.bulk-editing-padder-6 { left: 622px; }
  &.bulk-editing-padder-7 { left: 666px; }
  &.bulk-editing-padder-8 { left: 710px; }

  .bulk-editing {
    thead {
      position: relative;
      background-color: white;
      z-index: 1;

      tr {
        height: 48px;
      }
    }

    tr {
      height: 62px;
    }

    th, td {
      white-space: nowrap;
      padding: 0 6px;
    }

    .bulk-editing-name {
      input { width: 220px; }
    }

    .bulk-editing-page_title {
      min-width: 414px;

      input { width: 360px; }
    }

    .bulk-editing-url {
      input {
        width: 250px;
        margin-left: 4px;
      }
    }

    .bulk-editing-set_template_master_page {
      display: inline-block;
      width: 180px;

      .custom-react-select {
        margin-top: 8px;

        .react-select__control {
          min-height: auto;
        }
      }
    }

    .bulk-editing-publish_draft_version {
      width: 24px;
      text-align: center;
    }

    .bulk-editing-set_online_offline {
      width: 24px;
      text-align: center;
    }

    .bulk-editing-set_in_nav {
      width: 24px;
      text-align: center;
    }

    .bulk-editing-set_in_sitemap {
      width: 24px;
      text-align: center;
    }

    .bulk-editing-set_indexable {
      width: 24px;
      text-align: center;
    }

    .bulk-editing-instant_contact_form {
      width: 24px;
      text-align: center;
    }

    .bulk-editing-back_to_top {
      text-align: center;
    }

    .bulk-editing-delete_page {
      width: 24px;
      text-align: center;
    }
    .bulk-editing-display_review_widget {
      width: 24px;
      text-align: center;
    }
  }
}

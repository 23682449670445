@use '../../assets/css/sass/variables.scss' as *;

.app-container {
  position: relative;
}

.sidebar {
  .main-menu {
    ul {
      li {
        a {
          text-decoration: none;
        }
      }
    }
  }
  .sub-menu {
    ul {
      li {
        span {
          padding-top: 0;
        }
        i {
          width: 20px;
        }
        a {
          color: $color-grey;
          text-decoration: none;
        }
      }
    }
  }
}

.navbar {
  padding: 0 !important;

  .navbar-left, .navbar-right {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .navbar-left {
    justify-content: flex-start;
  }
  .navbar-right {
    justify-content: flex-end;
    padding-right: 15px;
  }

  .navbar-logo {
    flex-basis: 20%;
    height: 100%;

    .logo {
      background: url("Dashboard/src/assets/images/logo-mobile.png") no-repeat;
      background-position: center center;
      background-size: contain;
      width: 160px;
      height: 100px;
      margin-top: -10px; // The WG logo is slightly below centre because of the bars at the top.
    }
    .mobile-logo {
      background: url("Dashboard/src/assets/images/mobile-logo.png") no-repeat;
      background-size: contain;
      background-position: center;
      width: 40px;
      height: 40px;
      margin: 4px 8px;
    }
  }

  #DarkModeSwitch {
    margin: 0;
  }

  .header-icon {
    padding-left: 0.9rem;
    padding-right: 0.9rem;
  }
  .input-group-text { // search magnifying glass
    padding: 0.25rem;

    i {
      color: var(--secondary-color);
    }
  }
}

.navbar.mobile-device {
  height: auto;
  padding-top: 5px !important;
  .navbar-right {
    .input-group {
      input.form-control, .input-group-text {
        border: none;
        padding: 0;
      }
    }
  }
  .instance-selection-hover-cont {
    left: 0;
    right: 0;
    width: 100%;
  }
  .instance-selection-cont .InstanceSelection.react-select .react-select__menu {
    min-width: auto;
    width: 100%;
  }
}

.big-nav {
  overflow-y: scroll;
  font-size: 16px;
  width: calc(100vw - 200px);
  margin-top: 120px;
  margin-left: 180px;
  height: calc(100vh - 180px);

  .big-nav-header {
    padding-top: 12px;

    .input-group-text {
      padding: 0.375rem 0.75rem;
    }
  }

  .react-select {
    width: 400px;
  }

  input {
    line-height: 1;
  }

  h3 {
    border-bottom: 2px solid $color-sky-blue-light;
    padding-bottom: 3px;
    display: inline-block;
  }

  .page-tree {
    ul {
      list-style: none;
      padding: 0;
      margin-left: 20px;
    }
    .page {
      height: 40px;
      padding: 8px 0;

      .angle {
        display: inline-block;
        height: 10px;
        width: 10px;
        border-style: solid;
        border-color: #d7d7d7;
        border-width: 0 0 1px 1px;
        position: relative;
        left: -8px;
      }

      .actions a {
        text-decoration: none;
      }

      .actions i {
        display: none;
        cursor: pointer;
      }

      &:hover {
        .actions i { display: inline; }
      }
    }
  }

  .menu-items {
    padding-top: 1.5rem;

    a {
      text-decoration: none;
    }

    i {
      margin-right: 10px;
    }

    .sub-items {
      border-width: 0 0 0 1px;
      border-style: solid;
      border-color: #d7d7d7;
      padding-left: 10px;
      margin: 6px 0 0 10px;

      .sub-item {
        padding: 8px 0;
      }
    }

    .menu-item, .sub-item {
      cursor: pointer;

      &:hover {
        color: #45afd0;
      }
    }

    a[href*=nowhere] .menu-item, a[href*=nowhere] .sub-item {
      cursor: default;

      &:hover {
        color: initial;
      }
    }
  }
}

.search-box-open {
  main {
    display: none;
  }
}

.instance-selection-cont.mobile-device {
  background: var(--foreground-color);
  z-index: 1;
  position: fixed;
  top: 53px;
  left: 0;
  padding: 5px;
  right: 0;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
}

@media print {
  nav.navbar {
    display: none;
  }

  .sidebar {
    display: none;
  }

  .app-layout main {
    margin-left: 60px !important;
    margin-top: 20px !important;
  }

  .top-right-button-container {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  nav {
    i {
      font-size: 16px;
    }
  }

  .big-nav {
    margin: 90px 0 0 0;
    padding: 0 15px;
    width: 100%;
  }
  .instance-selection-cont .SiteSelection {
    position: unset;
    margin-top: 0;
  }
  .instance-selection-hover-cont,
  .site-selection-hover-cont {
    left: 0;
    width: 100% !important;
  }
  .instance-selection-cont .InstanceSelection.react-select .react-select__menu {
    min-width: 100%;
  }
}

$main-font: "Source Sans 3";
$main-menu-item-height: 110px;
$main-menu-width: 120px;
$main-menu-width-lg: 110px;
$main-menu-width-md: 100px;
$main-menu-width-xs: 90px;
$sub-menu-width: 260px;
$sub-menu-width-lg: 220px;
$sub-menu-width-md: 200px;
$sub-menu-width-xs: 190px;
$navbar-height: 100px;
$navbar-height-xs: 76px;
$navbar-height-md: 100px;
$navbar-height-lg: 100px;
$main-margin: 60px;
$main-margin-lg: 50px;
$main-margin-md: 40px;
$main-margin-xs: 15px;
$app-menu-width: 280px;
$main-menu-item-height-mobile: 90px;
$menu-collapse-time: 300ms;
$animation-time-long: 1000ms;
$animation-time-short: 200ms;
$border-radius: 0.1rem;
$theme-color-purple: #922c88;
$theme-color-blue: #145388;
$theme-color-green: #576a3d;
$theme-color-orange: #e2863b;
$theme-color-red: #880a1f;
$info-color: #3195a5;
$success-color: #3e884f;
$warning-color: #b69329;
$error-color: #c43d4b;

$center: center;
$width100P: 100%;
$font-weight6: 600;
$color-white: #fff;
$color-grey: #303030;
$color-green: #008000;
$color-yellow: #ceae2e;
$color-semi-grey: #ccc;
$color-grey-btn: #878787;
$color-blue-btn: #35A1C3;
$color-light-grey: #efefef;
$color-red-danger: #dc3545;
$color-sky-blue-dark: #17a2b8;
$success-color-green: #59bc6c;
$color-sky-blue-light: #45afd0;
$color-green-hover-btn: #2c8162;

$shadowOffsetsTop: 1 3 10 14 19;
$shadowBlursTop: 1.5 5 10 14 19;
$shadowOpacitiesTop: 0.04 0.1 0.19 0.25 0.3;

$shadowOffsetsBottom: 1 3 6 10 15;
$shadowBlursBottom: 3 6 6 5 6;
$shadowOpacitiesBottom: 0.04 0.1 0.2 0.22 0.22;

.StyleOverrideRow {
  td {
    vertical-align: middle;
  }

  label {
    margin-bottom: 0;
  }
}

.sticky-header{
  top: 100px;
  z-index: 10;
  background-color: #ffffff;
  padding: 15px 0;
  font-weight: bold;
  position: sticky;
}

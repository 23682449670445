@use '../../../../assets/css/sass/variables.scss' as *;

.ContentType {
  .preview-original-media {
    height: 100px;
    width: 100%;
    text-align: center;
    position: relative;
    text-align: center;
    display: inline-block;
    img {
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .editor-value {
    height: 200px;
    overflow-x: hidden;
    overflow-y: scroll;

    // Make contenteditable elements add <br> instead of <div>
    // https://stackoverflow.com/a/24689420/2272240
    display: inline-block !important;
    width: 100% !important;

    > * {
      width: $width100P;
      font-family: inherit;
    }
    a {
      color: $color-sky-blue-dark !important;
    }

    span.tbi {
      display: inline !important;
    }

    // Browsers do something very strange with bolding... If a text style has a font weight of less than 400, then
    // bolding add the <b> tag around it, and Bootstrap's reboot file applies the rule "font-weight: bolder". This
    // means one relative weight heavier than before, so it would go from eg 200 to 300. When you try to "unbold" a
    // piece of text which has been "bolded", the browser thinks that the text is already "unbold" (because the weight
    // is 400 or less), and so it doesn't do anything. This solves that problem by forcing <b> to be 700, which
    // browsers always think is "bold".
    b {
      font-weight: 700 !important;
    }
  }

  .editor-container {
    > div:focus {
      outline: none;
    }

    &.rte1, &.rte2 {
      ul {
        list-style: inside;
      }
      ol {
        list-style-position: inside;
      }
    }
  }

  .custom-ul {
    button.custom-simple-txt-btn.active-btn {
      background-color: #efefef;
      border-radius: 0;
    }
    button.custom-simple-txt-btn {
      li {
        padding: 0px;
      }
    }
  }

  .sortable-chosen .add-new-textblock {
    visibility: hidden;
  }

  // Some hackery to move the common buttons to the right of the toolbar
  // https://stackoverflow.com/a/8539107/2272240
  ul > .common-button {
    align-self: end;
    margin-left: auto;
  }

  ul > .common-button ~ .common-button {
    margin-left: 0;
  }
}

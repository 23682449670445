.EditSite {
  .card-columns {
    column-count: 2;

    .card {
      margin-bottom: .75rem;
      break-inside: avoid;
    }
  }
}

dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1000;
  margin: 0;
  padding: 30px 0 0 0;

  &::backdrop {
    backdrop-filter: blur(10px);
  }

  .dialog-content {
    background-color: white;
    border-radius: 8px;
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 0.5rem;
    max-width: 100%;
    margin: 0;
    overflow: hidden;
    width: 92%;

    @media (min-width: 992px) {
      padding: 1rem;

      &.lg, &.xl {
        width: 800px;
        max-width: calc(100% - (992px - 800px));
      }
    }

    @media (min-width: 1200px) {
      &.xl {
        width: 1140px;
        max-width: calc(100% - (1200px - 1140px));
      }
    }

    .dialog-body {
      padding: 0.5rem;

      @media (min-width: 992px) {
        padding: 1rem;
      }
    }

    .dialog-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      border-bottom: 1px solid #dee2e6;
      .dialog-title {
        margin: 0;
        font-size: 1.25rem;
        line-height: 1.5;
      }
      .close-btn {
        color: #000;
        background: transparent;
        border: 0;
        border-radius: 0.25rem;
        opacity: 0.7;
        font-size: 1.5rem;
        cursor: pointer;
        transition: opacity 0.2s ease;

        &:hover {
          opacity: 1;
        }
      }
    }

    .dialog-footer {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      border-bottom-right-radius: calc(-1px + 0.3rem);
      border-bottom-left-radius: calc(-1px + 0.3rem);
      padding: 0.75rem;
      border-top: 1px solid rgb(222, 226, 230);

      button {
        padding: 0.5rem 1rem;
        margin: 0 0.5rem;
        cursor: pointer;
        border-radius: 0.25rem;
        font-size: 1rem;
      }
    }
  }

  @media (max-width: 768px) {
    &.sm {
      width: 80%;
    }
    &.lg {
      width: 90%;
    }
    &.xl {
      width: 95%;
    }
  }
}

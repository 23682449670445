@use '../../../assets/css/sass/variables.scss' as *;

.PageSetting {
  .page-url-field{
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.PageVersionBox {
  button {
    padding: 0;
    line-height: 40px;
    border-radius: 50px;
    &:hover {
      color: $color-white;
    }
  }
  .page-history-button {
    border: 1px solid $color-grey-btn;
    color: $color-grey-btn;
    background: $color-white;
    &:hover{
      background: $color-grey-btn;
      border: 1px solid $color-grey-btn;
    }
  }

  .page-preview-button {
    border: 1px solid $success-color-green;
    color: $success-color-green;
    background: $color-white;
    &:hover {
      background: $color-green-hover-btn;
      border: 1px solid $color-green-hover-btn;
    }
  }
  .page-cancel-button {
    border: 1px solid $color-red-danger;
    color: $color-red-danger;
    background: $color-white;
    &:hover {
      background: $color-red-danger;
      border: 1px solid $color-red-danger;
    }
  }
}

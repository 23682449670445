@use '../../../assets/css/sass/variables.scss' as *;

.Pagination {
  width: $width100P;
  ul {
    li {
      padding: 6px;
      .active {
        background: $color-white;
        border: 1px solid $color-grey !important;
        color: $color-grey !important;
      }
      .disabled {
        border-color: #d7d7d7;
        color: #d7d7d7;
        background: $color-white;
      }
      .btn {
        min-width: 30px;
        height: 30px;
        line-height: .9;
        font-size: .76rem;
        background: transparent;
        border: none;
        color: $color-grey;
        &:hover {
          background-color: $color-white !important;
          border: 1px solid $color-grey !important;
          color: $color-grey !important;
        }
      }
      button.prev, button.next {
        background: $color-grey;
        color: $color-white;
        border: 1px solid $color-grey;
      }

    }
  }
  button.dropdown-toggle {
    border: 1px solid $color-grey;
    color: $color-grey;
    &:hover, &:active, &:focus, &:visited {
      color: $color-white !important;
      background-color: $color-grey !important;
      border: 1px solid;
    }
  }
}

@media only screen and (max-width: 768px) {
  .Pagination {
    ul {
      li {
        padding: 3px;
        .btn {
          min-width: 20px;
          height: 20px;
          font-size: 10px;
        }
      }
    }
  }
}

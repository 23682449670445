@use '../../../../assets/css/sass/variables.scss' as *;

.MediaToolBox {
  max-width: 80%;

  // Make the left side of all of the fields line up
  .BreakPoint ul {
    padding-left: 12px;
  }

  .media-preview-box {
    overflow: scroll;
    height: 350px;
    .preview-original-insidemedia {
      height: 350px;
      width: 100%;
      text-align: center;
      position: relative;
      img {
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }

  .modal-header {
    color: $color-white;
    background: $color-sky-blue-dark;
    border-radius: unset;
  }

  .dz-preview {
    display: none;
  }
}

.roi-report-list {
    thead {
        position: sticky;
        top: 100px;
        background: white;
    }

    th {
        padding: 12px 8px !important;
    }

    tr {
        vertical-align: middle;
    }
}

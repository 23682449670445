.ContentBriefingForm {
  nav {
    position: sticky;
    top: 100px; /* Height of the top nav bar */
    z-index: 2;

    color: #6c757d;

    .card-body {
      overflow: auto;
      white-space: nowrap;

      .breadcrumb {
        display: block;

        li {
          display: inline-block;
        }
      }
    }

    a {
      text-decoration: none;
      color: #303030;
      padding: 0.25rem 0.5rem;

      &.active {
        font-weight: bold;
      }
    }
  }

  a.anchor {
    position: relative;
    top: -170px;
  }
}

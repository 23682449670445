.email-input-with-domain {
  display: inline-block;
  border-width: 1px;
  border-style: solid;
  border-color: var(--separator-color);

  &:has(div:focus) {
    border-color: rgba(var(--theme-color-1), 0.6);
  }

  div {
    display: inline-block;
    padding: 0.7rem 0.75rem 0.65rem 0.75rem;
  }

  div:focus, div:focus + div {
    outline: 0;
  }

  .email-left {
    width: auto;
    border-right-width: 0;
    min-width: 150px;
  }

  .email-right {
    border-left-width: 0;
    color: gray;
  }
}

.Login {
  .login-divider {
    &::before,
    &::after {
      background: #c6d2d9;
      content: "";
      display: block;
      width: 100%;
      height: 1px;
    }
    span {
      padding: 0 .7143rem;
    }
  }
}
.auth-card {
  box-shadow: 0 22px 70px 4px rgba(0, 0, 0, 0.56);
  position: relative;
  .image-side-login {
    width: 40%;
    padding: 0;
    background: none;
  }
  .login-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .login-image {
    animation-duration: 16s;
    animation-iteration-count: infinite;
  }
  .login-image-1 {
    background-image: url("Dashboard/src/assets/images/login-1.jpg");
    animation-name: fade1;
  }
  .login-image-2 {
    background-image: url("Dashboard/src/assets/images/login-2.jpg");
    animation-name: fade2;
  }
  .login-image-3 {
    background-image: url("Dashboard/src/assets/images/login-3.jpg");
    animation-name: fade3;
  }
  @keyframes fade1 {
    0% {
      opacity: 1;
    }
    31% {
      opacity: 1;
    }
    38% {
      opacity: 0;
    }
    93% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade2 {
    0% {
      opacity: 0;
    }
    31% {
      opacity: 0;
    }
    38% {
      opacity: 1;
    }
    61% {
      opacity: 1;
    }
    68% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes fade3 {
    0% {
      opacity: 0;
    }
    61% {
      opacity: 0;
    }
    68% {
      opacity: 1;
    }
    93% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

.sketch-picker {
  #rbgcp-wrapper {
    // This is very hackish, but rbgcp doesn't have classes on these elements.
    div:nth-child(6) {
      div:nth-child(1) {
        border: 1px solid rgb(190, 190, 190);
      }

      div:nth-child(2) {
        div {
          border: 1px solid rgb(190, 190, 190);
        }
      }
    }
  }

  .selected-color-preset::after {
    content: '✓';
    color: white;
    display: flex;
    justify-content: center;
  }

  .selected-color-preset-high-contrast::after {
    content: '✓';
    color: black;
    display: flex;
    justify-content: center;
  }
}

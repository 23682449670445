.Wizard {
  ul.top-nav {
    list-style: none;
    flex-wrap: wrap;
    padding: initial;
    background: initial;
    position: relative;
    border: initial;
    margin-bottom: 1.5rem;

    &::before {
      content: " ";
      position: absolute;
      bottom: 1px;
      width: 100%;
      height: 1px;
      z-index: 0;
      background: #f3f3f3;
    }

    li {
      position: relative;
      padding: 0.5rem 1rem;
      text-align: center;

      a {
        color: #d7d7d7;
        span {
          color: #d7d7d7;
        }
        &::before {
          content: " ";
          position: absolute;
          margin-top: 10px;
          display: block;
          border-radius: 50%;
          color: initial;
          border: none;
          text-decoration: none;
          left: 50%;
          transform: translateX(-50%);
          background: #f3f3f3;
          width: 18px;
          height: 18px;
          z-index: 1;
          bottom: -6px;
        }
      }
      &.step-doing {
        a {
          &:after {
            background: #48494b;
            width: 10px;
            height: 10px;
            z-index: 2;
            bottom: -2px;
            content: " ";
            position: absolute;
            margin-top: 10px;
            display: block;
            border-radius: 50%;
            color: initial;
            border: none;
            text-decoration: none;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
      &.step-done {
        a {
          &:after {
            background: initial;
            background-image: url('../../../assets/images/checked.svg');
            width: 10px;
            height: 10px;
            z-index: 2;
            bottom: -2px;
            content: " ";
            position: absolute;
            margin-top: 10px;
            display: block;
            border-radius: 50%;
            color: initial;
            border: none;
            text-decoration: none;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }

    &.disabled {
      .step-doing {
        a {
          color: #48494b!important;
          span {
            color: #48494b!important;
          }
        }
      }
    }
  }
}
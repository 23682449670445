@use '../../../assets/css/sass/variables.scss' as *;

.spinner-outer {
  height: $width100P;
  width: $width100P;
  position: fixed;
  background-color: #ffffff9e;
  top: 0;
  z-index: 999999;
  .spinner-inner {
    height: $width100P;
    width: $width100P;
    display: flex;
    align-items: $center;
    justify-content: $center;
    img {
      width: 100px;
    }
  }
}
